
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { saveFile } from "@/core/services/FileSaverService";
import { useStore } from "vuex";

export default defineComponent({
  name: "ReportIndex",
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageTitle("Энгийн тайлан");
    });

    const orgname = computed(() => {
      return store.getters.orgName;
    });

    const isu = computed(() => {
      return store.getters.getMbUser;
    });

    const orgcode = computed(() => {
      return store.getters.orgCode;
    });

    return {
      isu,
      orgname,
      orgcode
    };
  },
  data() {
    return {
      loadingOrg: false,
      loadButton: "off",
      chooseStatus: "03",
      selectOrgList: [],
      selectValOrg: ""
    };
  },
  mounted() {
    if (this.isu) this.getOrg();
  },
  computed: {
    filteredOrgList(): any {
      return this.selectOrgList.filter((data: any) => {
        return (
          !this.chooseStatus ||
          data.selectOrgTypeCode.includes(this.chooseStatus.toLowerCase())
        );
      });
    }
  },
  methods: {
    async getOrg() {
      this.loadingOrg = true;
      await ApiService.get(`org`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.selectOrgList = results.response.map(org => ({
              selectValOrg: org.orgcode,
              selectOrgTypeCode: org.orgtypecode,
              selectLabelOrg: org.orgname
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadingOrg = false;
        });
    },
    async getReport() {
      document.body.classList.add("waiting");
      this.loadButton = "on";
      await ApiService.get("reportindex", "?type=balance_report")
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              "balance_report",
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          document.body.classList.remove("waiting");
          this.loadButton = "off";
        });
    },
    async getReportPost() {
      document.body.classList.add("waiting");
      this.loadButton = "on";
      const params = {
        type: "balance_report",
        orgcode: this.selectValOrg
      };
      await ApiService.post("reportindex", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else {
            saveFile(
              results.response,
              this.selectValOrg,
              "application/vnd.ms-excel",
              ".xls"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Тайлан татахад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          document.body.classList.remove("waiting");
          this.loadButton = "off";
        });
    }
  }
});
